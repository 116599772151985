.dp {
    width: 100%;
    margin: 0px;
    position: relative;
    user-select: none;
    text-align: center;
    max-width: 150px;
    border: #333;
}

.dp .dp-btn {
    padding: 10px 10px;
    background: #fff;
    color: #333;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    text-align: center;
}

.dp .dp-content {
    position: absolute;
    top: 110%;
    padding: 10px;
    background: #fff;
    color: #333;
    width: 100%;
    z-index: 99;
    border: 1px solid #ced4da;
}

.dp .dp-content .dp-item {
    padding: 10px;
    cursor: pointer;
    transition: all 0.5s;
    z-index: 99;
}

.dp .dp-content .dp-item:hover {
    background: #dddddd;
}